import { Navigate, Outlet, useLocation } from "react-router";

const V2Navigation = () => {
  const location = useLocation();
  const { pathname, search, hash } = location;
  return pathname === "/" ? (
    <Navigate to={{ pathname: "/v2", search, hash }} state={{ ...location }} />
  ) : pathname === "/v1" ? (
    <Navigate
      to={{ pathname: "/v2/default", search, hash }}
      state={{ ...location }}
    />
  ) : !pathname.includes("/v2") ? (
    <Navigate
      to={{ pathname: `/v2${pathname}`, search, hash }}
      state={{ ...location }}
    />
  ) : (
    <Outlet />
  );
};

export default V2Navigation;
