import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppLayout from "../pages/_layout";
import { DotPulseLoader } from "freespace-react-components";
import React, { Suspense } from "react";
import V2Navigation from "../components/V2Navigation";

const AppRoute = React.lazy(() => import("./route"));
const AppContainer = () => {
  return (
    <Router>
      <Routes>
        <Route caseSensitive path={"/"} element={<V2Navigation />} />
        <Route caseSensitive path={"/v1/*"} element={<V2Navigation />} />
        <Route
          caseSensitive
          path={"/v2/*"}
          element={
            <AppLayout>
              <Suspense
                fallback={
                  <div
                    className={
                      "w-screen h-screen flex items-center justify-center"
                    }
                  >
                    <DotPulseLoader
                      buttonVariant={"outline"}
                      color={"primary"}
                    />
                  </div>
                }
              >
                <AppRoute />
              </Suspense>
            </AppLayout>
          }
        />
        <Route caseSensitive path={"/*"} element={<V2Navigation />} />
        <Route caseSensitive path={"*"} element={<V2Navigation />} />
      </Routes>
    </Router>
  );
};

export default AppContainer;
