import React from "react";
import AppContainer from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastBox from "./components/ToastBox";

function App() {
  return (
    <React.StrictMode>
      <ToastContainer />
      <AppContainer />
    </React.StrictMode>
  );
}

export default App;
